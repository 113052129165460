var hostname = window.location.hostname;

var api_base_url = false;
if (hostname.includes("localhost")) {
	//api_base_url = "http://api.sdm.localhost";
	api_base_url = "https://api.dev.yellgo.cloud";
} else if (hostname.includes(".demo.")) api_base_url = "https://api.demo.yellgo.cloud";
else if (hostname.includes(".devaws.")) api_base_url = "https://api.dev.yellgo.cloud";
else if (hostname.includes(".dev.")) api_base_url = "https://api.dev.yellgo.cloud";
else api_base_url = "https://api.yellgo.cloud";

let api_key = null;

async function oauth() {
	const response = await fetch(api_base_url + "/oauth/", {
		method: "POST",
		body: JSON.stringify({
			client_id: "yellgo_kitchenmonitor",
			client_secret: "8f8e16b6968afb96538339c761fc84d6",
			host: hostname,
		}),
	});

	const json = await response.json();
	//console.log(json);
	if (json.success == 0) alert(json.error);

	api_key = json.token;
	return json;
}

async function post(endpoint, request) {
	const response = await fetch(api_base_url + endpoint, {
		method: "POST",
		headers: {
			"Shared-Key": api_key,
		},
		body: JSON.stringify(request),
	});

	const json = await response.json();

	return json;
}

export default { oauth, post };
